import React, { createRef, useEffect, useState } from 'react';
import { Modal, Image } from 'react-bootstrap';
import { calender } from '../../../assets/SVG/SVG';
import moment from 'moment';
import { ReactMediaRecorder } from 'react-media-recorder';

import Button from "../../../components/buttons";
import "../../../layout/HistoryModal/HistoryModal.css";
import cross from "../../../assets/upgrade-package/cross.png";
import theme from "../../../theme";
import StyledButton from '../../buttons';
import { CONVERSATION_URL, IDENTITY_URL, ROLES } from "../../../Global/Constants/constants";
import { ReturnProps } from "../../../@types/functions";
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';

import "../../../layout/VideoModal/VideoModal.css";
import ReactPlayer from 'react-player';
import Actions from './defaults/render-actions';
import AlertInfo from '../../alerts/AlertInfo';
import { deleteIcon } from '../../../assets/SVG/SVG';
import { injectMetadata } from '../../../Global/Utilities/videoConverter';

interface VideoModalInterface {
    videoDate?: string;
    videoID?: string;
    videoUrl?: string;
    show: Boolean;
    hide: () => void;
    update?: () => void;
}
const VideoModal: React.FC<VideoModalInterface> = ({
    show,
    videoDate,
    videoID,
    videoUrl,
    hide,
    update = () => { }
}) => {
    const videoRecorderRef = createRef<any>();
    const [success, setSuccess] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    const [videoBlob, setVideoBlob] = useState<any>(null);
    const [teacherRate, setTeacherRate] = useState<any>();
    const [systemRate, setSystemRate] = useState<any>();

    const [recording, setRecording] = useState(false);
    const [timer, setTimer] = useState(60);
    const [isVideoRecord, setIsVideoRecord] = useState(!videoUrl ? true : false);

    const handleNewVideo = () => {
        // Reset all states
        setIsVideoRecord(true);
        setVideoBlob(null);
        setRecording(false);
        setTimer(60);
        setTeacherRate(null);
        setSystemRate(null);
        setError("");
        setShowError(false);
        setSuccess("");
        setShowSuccess(false);
        
        // Reset video recorder
        if (videoRecorderRef.current) {
            videoRecorderRef.current.srcObject = null;
        }
    };

    useEffect(() => {
        let interval: number;
        if (recording && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setRecording(false);
            handleStopRecording();
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [recording, timer]);

    const handleStartRecording = () => {
        setRecording(true);
        setTimer(60);
    };

    const handleStopRecording = () => {
        setRecording(false);
        setTimer(60);
    };

    const UploadVideo = async () => {
        var data = new FormData();
        data.append('file', (videoBlob as Blob));
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const res = await trackPromise(axios.post<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/video", data, config));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        else {
            setSuccess("Record updated");
            setShowSuccess(true);
            update();
            hide();
        }
    }

    const DeleteVideo = async () => {
        const res = await trackPromise(axios.delete<any, ReturnProps>(IDENTITY_URL + "/api/v1/users/video"));
        if (!res.success) {
            setError(res.error as string);
            setShowError(true)
            return null;
        }
        else {
            setSuccess("Record updated");
            setShowSuccess(true);
            update();
            hide();
        }
    }

    return (
        <React.Fragment>
            <AlertInfo show={showError} setShow={setShowError} isAutoHide={true} message={error} type={0} />
            <AlertInfo show={showSuccess} setShow={setShowSuccess} isAutoHide={true} message={success} type={1} />

            <div className="greybgHeader">
                <Modal className="historyModal" show={show} onHide={hide} backdrop="static">
                    <Modal.Header className="historyHeader">
                        <Modal.Title>
                            <h4 className="h4">Profile Video</h4>
                        </Modal.Title>
                        <Image
                            onClick={hide}
                            src={cross}
                            height={40}
                            width={40}
                            style={{ cursor: "pointer" }}
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div className="content">
                            {!isVideoRecord ?
                                <div>
                                    <div className="video-container">
                                        <ReactPlayer onError={() => {
                                            setIsVideoRecord(true);
                                        }} width="560" height="315" controls={true} url={videoUrl} />
                                    </div>
                                    <div className="button-container">
                                        <Button type="tutorsCardBtn" className="button-danger" onClick={DeleteVideo}>Delete Video</Button>
                                        <Button type="tutorsCardBtn" secondary onClick={handleNewVideo}>New Video</Button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="video-recorder-container">
                                        <ReactMediaRecorder
                                            video
                                            askPermissionOnMount
                                            render={({ status, startRecording, stopRecording, mediaBlobUrl, previewStream, error }) => {
                                                if (error) {
                                                    console.error('Recording error:', error);
                                                    setError("Failed to access camera: " + error);
                                                    setShowError(true);
                                                }

                                                React.useEffect(() => {
                                                    if (videoRecorderRef.current && previewStream && (status === "idle" || status === "acquiring_media")) {
                                                        videoRecorderRef.current.srcObject = previewStream;
                                                    }
                                                }, [previewStream, status]);

                                                // Auto-start recording when previewStream is ready and status is idle
                                                React.useEffect(() => {
                                                    const shouldStartRecording = status === "idle" && 
                                                        previewStream && 
                                                        !recording && 
                                                        !videoBlob;  // Only start if there's no existing video blob
                                                    
                                                    if (shouldStartRecording) {
                                                        startRecording();
                                                        handleStartRecording();
                                                    }
                                                }, [status, previewStream, videoBlob]);

                                                // Stop recording when timer ends
                                                React.useEffect(() => {
                                                    if (timer === 0 && status === "recording") {
                                                        stopRecording();
                                                        handleStopRecording();
                                                    }
                                                }, [timer, status]);

                                                if (videoRecorderRef.current && previewStream && (status === "idle" || status === "acquiring_media")) {
                                                    videoRecorderRef.current.srcObject = previewStream;
                                                }
                                                return (
                                                    <div>
                                                        {status !== "stopped" && (
                                                            <div className="video-preview">
                                                                <video 
                                                                    ref={videoRecorderRef}
                                                                    style={{ width: "100%", maxWidth: "560px", height: "315px" }}
                                                                    autoPlay
                                                                    muted
                                                                />
                                                            </div>
                                                        )}
                                                        {mediaBlobUrl && status === "stopped" && (
                                                            <div className="video-preview">
                                                                <video 
                                                                    src={mediaBlobUrl}
                                                                    style={{ width: "100%", maxWidth: "560px", height: "315px" }}
                                                                    controls
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="recording-controls">
                                                            {status !== "recording" && status !== "stopped" && (
                                                                <Button 
                                                                    type="tutorsCardBtn" 
                                                                    onClick={() => {
                                                                        startRecording();
                                                                        handleStartRecording();
                                                                    }}
                                                                >
                                                                    Start Recording
                                                                </Button>
                                                            )}
                                                            {status === "recording" && (
                                                                <div className="button-container">
                                                                    <div className="timer">Time remaining: {timer}s</div>
                                                                    <Button 
                                                                        type="tutorsCardBtn"
                                                                        secondary
                                                                        onClick={() => {
                                                                            stopRecording();
                                                                            handleStopRecording();
                                                                        }}
                                                                    >
                                                                        Stop Recording
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {status === "stopped" && mediaBlobUrl && (
                                                            <div className="button-container">
                                                                <Button 
                                                                    type="tutorsCardBtn" 
                                                                    primary
                                                                    onClick={() => {
                                                                        fetch(mediaBlobUrl)
                                                                            .then(response => response.blob())
                                                                            .then(blob => {
                                                                                setVideoBlob(blob);
                                                                            });
                                                                    }}
                                                                >
                                                                    Ok
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                    {videoBlob &&
                                        <div className="button-container">
                                            {/* <Button 
                                                type="tutorsCardBtn" 
                                                secondary 
                                                onClick={() => {
                                                    handleNewVideo();
                                                    // Force a small delay to ensure states are reset
                                                    setTimeout(() => {
                                                        if (videoRecorderRef.current) {
                                                            videoRecorderRef.current.srcObject = null;
                                                        }
                                                    }, 100);
                                                }}
                                            >
                                                New Video
                                            </Button> */}

                                            <Button 
                                                type="tutorsCardBtn" 
                                                primary 
                                                onClick={UploadVideo}
                                            >
                                                Upload Video
                                            </Button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default VideoModal;
